<template>
  <div class="media__main container">
    <h1>{{ $fn.tr('Media') }}</h1>
    <div class="media__filters">
      <div class="media__filter">
        <div class="media__select" :class="filter == 1 ? 'active' : ''">
          <div class="media__select-main">
            <div class="media__select-layer"></div>
            <div class="media__select-close" @click="filterClick(false)">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.2071 12.9142L23 23.7071L23.7071 23L12.9142 12.2071L23.7071 1.41421L23 0.707107L12.2071 11.5L0.707107 0L0 0.707107L11.5 12.2071L0 23.7071L0.707107 24.4142L12.2071 12.9142Z"
                      fill="black"/>
              </svg>
            </div>
            <div class="media__select-inn">
              <div class="media__select-item" :class="item.title == filterActiveName ? 'active' : ''"
                   v-for="item in filterData" :key="item.id" @click="filterFunc(item.title,item.id)">{{ item.title }}
              </div>
            </div>
          </div>
        </div>
        <span @click="filterClick(1)">{{ $fn.tr('Filter by category') }}</span>
      </div>
      <div class="media__filter">
        <div class="media__select" :class="filter == 2 ? 'active' : ''">
          <div class="media__select-main">
            <div class="media__select-layer"></div>
            <div class="media__select-close" @click="filterClick(false)">
              <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.2071 12.9142L23 23.7071L23.7071 23L12.9142 12.2071L23.7071 1.41421L23 0.707107L12.2071 11.5L0.707107 0L0 0.707107L11.5 12.2071L0 23.7071L0.707107 24.4142L12.2071 12.9142Z"
                      fill="black"/>
              </svg>
            </div>
            <div class="media__select-inn">
              <el-date-picker
                  v-model="dateFilter"
                  type="month"
                  placeholder="Pick a month"
                  :teleported="false"
                  ref="datePicker"
              />
            </div>
          </div>
        </div>
        <span @click="filterClick(2)">{{ $fn.tr('Filter by date') }}</span>
      </div>
    </div>
    <div class="media__items">
      <div class="media__item" v-for="item in data">
        <router-link class="media__item-half"
                     :to="`/${store.indx.locale}/media/singleview/${item.id}-${(item.title).replace(/ /g, '-')}`">
          <div class="media__item-img">
            <div class="media__item-imgIn">
              <picture>
                <source media="(max-width: 767px)" :srcset="$get(item,'innerCover.0.devices.mobile')"/>
                <source data-scroll-speed="-1" media="(max-width: 1023px)"
                        :srcset="$get(item,'innerCover.0.devices.tablet')"/>
                <img data-scroll data-scroll-speed="-1" :src="$get(item,'innerCover.0.devices.desktop')"
                     alt=""/>
              </picture>
            </div>
          </div>
        </router-link>
        <div class="media__item-half">
          <div class="media__item-descr">
            <h3>{{ $get(item, 'title') }}</h3>
            <p>{{ $get(item, 'teaser') }}</p>
            <router-link :to="`/${store.indx.locale}/media/singleview/${item.id}-${(item.title).replace(/ /g, '-')}`">
              {{ $fn.tr('See more') }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="media__loader" id='loaderRef' :class="blocker ? 'active' : ''">
        <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M121 61C121 73.8283 116.888 86.3189 109.269 96.6389C101.649 106.959 90.9221 114.565 78.6622 118.341C66.4023 122.118 53.2549 121.865 41.149 117.621C29.0432 113.377 18.6163 105.364 11.3984 94.7592C4.18056 84.1542 0.551667 71.5149 1.04425 58.6961C1.53683 45.8773 6.12495 33.5538 14.1354 23.5339C22.1458 13.514 33.1568 6.32532 45.5526 3.0226C57.9485 -0.28012 71.0765 0.477042 83.0105 5.18299"
              stroke="black" stroke-width="2"/>
        </svg>
      </div>
    </div>
  </div>
</template>
<script setup>
import {computed, nextTick, onMounted, ref, watch} from "vue";
import {useRoute} from 'vue-router';
import {useMenuStore} from "@/store/pinia/menu/index"
import fn from "../../../helpers/func";

let filter = ref(false);
let filterActiveName = ref(false);
let dateFilter = ref('');
let paginatedData = ref([]);
let pageNow = ref(1);
let store = useMenuStore();
let listCount = ref(props.contentData?.data.listCount)
const limit = 10
const filterClick = (value) => {
  filter.value = value;

}

let props = defineProps(
    {
      openNav: {
        required: false,
      },
      contentData: {
        type: Object,
      }
    }
);
let data = computed(() => {
  let filteredData = [];
  if (paginatedData.value.length === 0) {
    filteredData = props.contentData?.data?.list
  } else {
    filteredData = paginatedData.value
  }

  return filteredData
});
let blocker = false;
let filterData = computed(() => store?.indx?.terms?.Media_filter);

async function sendReq(page, searchId) {
  if (listCount.value <= limit * page) return
  const formData = {
    contentType: "media",
    pageNumber: page,
    searchTerms: searchId,
    limit: limit.value
  };
  let tmp = await fn.postData("main/getDataList", formData);
  if (tmp.status == 200) {
    blocker = false;
    if (paginatedData.value.length === 0) {
      paginatedData.value = tmp.data.list;
      listCount.value = tmp.data.listCount;
    } else {
      paginatedData.value = paginatedData.value.concat(tmp.data.list);
      setTimeout(() => {
        store.Loco.update()
      }, 1500)
    }
  }

}

const paginate = () => {
  setTimeout(() => {
    store.Loco?.on('scroll', func => {
      if (func.scroll.y > document.getElementById('loaderRef').offsetTop - 400 && !blocker) {
        blocker = true;
        pageNow.value += 1

        sendReq(pageNow.value, '')
      }
    });
  }, 1000)
}
let filterFunc = (name, id) => {
  filterActiveName.value = name;
  paginatedData.value = [];
  sendReq(1, id)
  filterClick(false);
}
onMounted(async () => {
  paginate()
  sendReq(1, '')
})
watch(dateFilter, () => {
  sendReq(1, dateFilter.value)
});

</script>
<style lang="scss">
.media__item-imgIn {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  -webkit-clip-path: polygon(0% 21.6%, 33.7% 21.6%, 33.7% 0%, 69.3% 0%, 69.3% 8.3%, 100% 8.3%, 100% 100%, 13% 100%, 13% 77.94%, 0% 77.94%);
  clip-path: polygon(0% 21.6%, 33.7% 21.6%, 33.7% 0%, 69.3% 0%, 69.3% 8.3%, 100% 8.3%, 100% 100%, 13% 100%, 13% 77.94%, 0% 77.94%);
}

picture {
  position: relativeabsolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  overflow: hidden;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.media__item-half {
  overflow: hidden;
}

//img {
//  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
//}
.media__item-half:hover picture {
  transform: scale(1.05);
}

.el-month-table td {
  padding: 0px !important;
}

.el-date-picker__header-label {
  color: rgba(0, 0, 0, 1);
}

.el-month-table td div {
  display: flex;
}

.el-month-table td .cell {
  color: rgba(0, 0, 0, 1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-radius: 0px;
  display: flex;
  margin: 0px auto;
}

.el-month-table td .cell:hover {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.el-date-picker__header--bordered {
  border-bottom: 0px !important;
}

.el-date-editor.el-input {
  display: none;
}

.el-picker__popper {
  position: relative !important;
  width: 100% !important;
  left: 0px !important;
  top: 0px !important;
  display: flex !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.el-date-picker .el-picker-panel__content {
  width: 100%;
  margin-left: 0px;
}

.el-month-table td .cell, .el-month-table td div {
  width: auto;
  height: auto;
  padding: 0px;
}

.el-picker__popper tbody, .el-picker__popper tr {
  display: flex;
  flex-direction: column;
}

.el-date-picker {
  width: 100%;
}

.media__loader {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 0px;
  transition: .6s;
}

.media__loader.active {
  max-height: 100px;
}

h1 {
  font-size: 74px;
  line-height: 90px;
  margin: 0px auto;
  text-align: center;
}

.media__filters {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  position: relative;
  z-index: 10;
}

.media__filter {
  display: flex;
  margin: 0px 20px;
  position: relative;
}

.media__select {
  position: absolute;
  top: 55px;
  right: 0px;
  width: 100%;
  pointer-events: none;
}

.media__select.active {
  pointer-events: all;
}

.media__select-main {
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 1);
  background: #fff;
  position: relative;
  opacity: 0;
  transition-delay: .6s;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
}

.media__select.active .media__select-main {
  opacity: 1;
  transition-delay: 0s;
}

.media__select-close {
  display: flex;
  margin-left: auto;
  margin-top: 15px;
  margin-right: 15px;
  position: relative;
  z-index: 10;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transform: scale(.8);
  opacity: 0;
  transition-delay: .4s;
  cursor: pointer;
}

.media__select.active .media__select-close {
  transform: scale(1);
  opacity: 1;
  transition-delay: 0s;
}

.media__select-close:hover {
  opacity: .4 !important;
}

.media__select-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: -40px;
  top: 20px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 1);
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  transform: translateX(10px);
  transition-delay: .5s;
}

.media__select.active .media__select-layer {
  transform: translateX(0px);
  transition-delay: 0s;
  opacity: 1;
}

.media__select-layer:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 39px;
  top: -19px;
  background: #fff;
}

.media__select-inn {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 5;
  padding-right: 40px;
  align-items: center;
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  max-height: 0px;
  overflow: hidden;
  transition-delay: .6s;
  transform: translateY(-10px);
}

.media__select.active .media__select-inn {
  max-height: 600px;
  transition-delay: .1s;
  transform: translateX(0px);
}

.media__select-item {
  display: flex;
  font-size: 21px;
  line-height: 32px;
  margin-bottom: 25px;
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  cursor: pointer;
}

.media__select-item:hover, .media__select-item.active {
  border-bottom: 1px solid rgba(0, 0, 0, 1);
}

.media__filter span {
  font-size: 28px;
  line-height: 34px;
  color: rgba(156, 156, 156, 1);
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  cursor: pointer;
}

.media__filter > span {
  border-bottom: 1px solid rgba(156, 156, 156, 1);
  padding-bottom: 5px;
}

.media__select.active ~ span, .media__filter span:hover {
  color: #000;
  border-color: #000;
}

.media__items {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;
}

.media__item {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
}

.media__item-half {
  width: 50%
}

.media__item-img img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0px;
  left: 0px;
}

.media__item-img {
  position: relative;
  width: 100%;
  padding-top: 70%;
  overflow: hidden;
}

.media__item-descr {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.media__item:nth-child(odd) .media__item-descr {
  padding-left: 40px;
}

.media__item:nth-child(even) .media__item-descr {
  padding-right: 40px;
}

.media__item:nth-child(even) {
  flex-direction: row-reverse;
}

.media__item-descr h3 {
  font-size: 48px;
  line-height: 58px;
  margin-bottom: 25px;
}

.media__item-descr p {
  font-size: 34px;
  line-height: 41px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  text-align: center
}

.media__item-img-layer {
  position: absolute;
  background: #fff;
  z-index: 3;
}

.layer1 {
  position: absolute;
  left: -1px;
  top: -2px;
  width: 300px;
  height: 130px;
}

.layer2 {
  position: absolute;
  left: -1px;
  bottom: -1px;
  width: 120px;
  height: 130px;
}

.layer3 {
  position: absolute;
  right: -1px;
  top: -2px;
  width: 250px;
  height: 60px;
}

.media__item-descr a {
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  position: relative;
  font-size: 36px;
  line-height: 50px;
  margin-top: 55px;
}

.media__item-descr a:before {
  transition: .6s cubic-bezier(0.79, 0.01, 0.15, 0.99);
  content: '';
  position: absolute;
  left: 0px;
  bottom: 0px;
  height: 1px;
  width: 100%;
  background: #000;
}

.media__item-descr a:hover:before {
  transform: scaleX(0)
}

//Laptop
@media (max-width: 1900px) {
  h1 {
    font-size: 48px;
    line-height: 58px;
  }
  .media__filters {
    margin-top: 40px;
  }
  .media__filter span {
    font-size: 21px;
    line-height: 25px;
  }
  .media__select-item {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 18px;
  }
  .media__item-descr h3 {
    font-size: 34px;
    line-height: 38px;
  }
  .media__item-descr p {
    font-size: 28px;
    line-height: 34px;
  }
  .media__item-descr a {
    font-size: 28px;
    line-height: 38px;
  }
  .layer1 {
    width: 250px;
    height: 100px;
  }
  .layer3 {
    width: 200px;
    height: 40px;
  }
}

//Ipad
@media (max-width: 1279px) {
  h1 {
    font-size: 48px;
    line-height: 58px;
  }
}

//Mobile
@media (max-width: 767px) {
  .media__filters {
    gap: 10px;
  }
  .media__filter {
    margin: 0px;
  }
  .media__item-descr p {
    font-size: 20px;
    line-height: 24px;
  }
  .media__item-descr {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .media__item-descr h3 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  .media__item-descr a {
    font-size: 24px;
    line-height: 50px;
    margin-top: 15px;
  }
  .media__items {
    margin-top: 40px;
  }
  .layer1 {
    width: 100px;
    height: 30px;
  }
  .layer2 {
    left: auto;
    bottom: -1px;
    width: 110px;
    height: 20px;
    right: -1px;
  }
  .layer3 {
    display: none;
  }
  .media__item-img {
    padding-top: 100%;
  }
  .media__item {
    flex-direction: column !important;
  }
  .media__item-half {
    width: 100%;
  }
  h1 {
    font-size: 24px;
    line-height: 28px;
  }
  .media__filter span {
    font-size: 14px;
    line-height: 24px;
    color: #000;
  }
  .media__select-layer {
    left: -20px;
    top: 10px;
  }
  .media__select-layer:after {
    left: 19px;
    top: -10px;
  }
}
</style>
